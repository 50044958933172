import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import EventEmitter from 'events';

// If you want to expose a new function from `index.js`, add it here and it'll work from production
// automagically. Keep in mind that return values are discarded.
const functions = ['open', 'getHeight'];

// Origin will be replaced as close as possible to each environment (usually during deploy, see .gitlab-ci.yaml)
// Examples dev-mode: https://widget.local.trialbee.xyz, prod-mode: https://widget.trialbee.com
let origin = '____ORIGIN____';

if (process.env.NODE_ENV === 'development') {
  origin = process.env.ORIGIN;
  console.log('🍯 We are in DEV_MODE');
  console.log('🍯 NODE_ENV', process.env.NODE_ENV);
  console.log('🍯 Origin', origin);
}

const FRAME_SOURCE = [origin, 'selfAssessment.html'].join('/');

// HACK: Used to detect iOS in order to handle scroll locking differently
const isIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform);

function appendIframeStyles(document) {
  const css = `
iframe.tb-self-assessment {
  border: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 999999999;
}

body.self-assessment-open iframe.tb-self-assessment {
  display: block;
}

@media print {
  body.self-assessment-open > *:not(.tb-self-assessment) {
    display: none;
  }
  body.self-assessment-open iframe.tb-self-assessment {
    display: block !important;
    position: absolute;
  }
}
`;

  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  document.head.appendChild(style);
}

class TrialbeeSelfAssessment extends EventEmitter {
  constructor() {
    super();

    appendIframeStyles(document);

    // This is programmer speak for "please don't touch our things, thank you ❤️"
    this._frameName = `tb-self-assessment-${Math.floor(Math.random() * 1000)}`;

    this._frame = document.createElement('iframe');
    this._frame.name = this._frameName;

    this._frame.src = FRAME_SOURCE;
    this._frame.allow = 'geolocation';

    this._frame.classList.add('tb-self-assessment');
    this._frame.setAttribute('aria-hidden', 'true');
    this._frame.setAttribute('title', 'Sign up for study');

    this._frame.addEventListener('load', () => {
      functions.forEach((f) => {
        this[f] = this._proxy.bind(this, f);
      });

      this.emit('load', this._frameName);
    });
    document.body.appendChild(this._frame);

    window.addEventListener('message', (e) => {
      if (
        e.source !== window.frames[this._frameName] ||
        e.origin !== origin ||
        typeof e.data !== 'string'
      ) {
        return;
      }

      const { type, options } = JSON.parse(e.data);
      if (type === 'open') {
        this._onshow();
        this.emit('open', this._frameName);
      } else if (type === 'close') {
        this._onhide();
        this.emit('close', this._frameName);
        if (options && options.redirectUrl) {
          window.location.href = options.redirectUrl;
        }
      } else if (type === 'done') {
        const [resolve] = this._callbacks[options.callbackId];
        resolve(options.returnValue);
        delete this._callbacks[options.callbackId];
        this.emit('done', this._frameName);
      } else if (type === 'setHeight') {
        this._frame.style.height = `${options.scrollHeight}px`;
      } else if (type === 'error') {
        const [_, reject] = this._callbacks[options.callbackId];
        reject(options.message);
        delete this._callbacks[options.callbackId];

        if (options.fatal) {
          this._onhide();
        }
        this.emit('error', options.message, options.fatal);
      } else if (type === 'action') this._onaction(options);
    });

    window.addEventListener('beforeprint', () => {
      this._proxy('getHeight');
    });

    window.addEventListener('afterprint', () => {
      this._frame.style.height = '';
    });

    this._callbacks = {};
  }

  _proxy(f, options) {
    return new Promise((resolve, reject) => {
      let callbackId = 0;
      while (callbackId in this._callbacks) callbackId += 1;
      this._callbacks[callbackId] = [resolve, reject];

      window.frames[this._frameName].postMessage(
        JSON.stringify({
          f,
          callbackId,
          options,
        }),
        origin,
      );
    });
  }

  _onshow() {
    this._previousFocusElement = document.activeElement;
    document.body.classList.add('self-assessment-open');
    this._frame.removeAttribute('aria-hidden');
    // this._frame.focus();
    if (!isIosDevice) {
      disableBodyScroll(this._frame);
    } else {
      document.body.style.position = 'fixed';
      document.body.style.height = '100%';
      document.documentElement.style.height = '100%';
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.overflow = 'hidden';
    }
  }

  _onhide() {
    document.body.classList.remove('self-assessment-open');
    this._frame.setAttribute('aria-hidden', 'true');
    if (!isIosDevice) {
      clearAllBodyScrollLocks();
    } else {
      document.body.style.position = '';
      document.body.style.height = '';
      document.documentElement.style.height = '';
      document.documentElement.style.position = '';
      document.documentElement.style.overflow = '';
    }
    if (this._previousFocusElement) {
      this._previousFocusElement.focus();
    }
  }

  _onaction(options) {
    this.emit('action', options.action, options.values);
  }
}

window.TrialbeeSelfAssessment = TrialbeeSelfAssessment;
// Deprecated: use the above instead.
window.TrialbeeRecruitmentWidget = TrialbeeSelfAssessment;
